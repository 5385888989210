<template>
  <div>
    <h1>Sidan hittades inte</h1>

    <p>
      <a href="/">Gå till startsidan</a>
    </p>
  </div>
</template>

<script>
  export default {
    name: 'NotFound'
  };
</script>

<style lang="stylus" scoped>
  @require '~global/colors'
  @require '~global/typo'

  div
    height: 80vh
    width: 100%
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center

  h1
    @extend .t-header
    text-align: center

  img
    width: 100%
    max-width: 500px
    margin-bottom: 2rem
</style>
