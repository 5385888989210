import axios from 'lib/axios';
import { cloneDeep } from 'lodash';
import { nanoid } from 'nanoid';

const articleBase = {
  name: null,
  sku: null,
  color: null,
  claimReason: null,
  images: new Array(3).fill(null),
  previewImages: new Array(3).fill(null),
  completed: false,
  qty: null
};

const claimBase = {
  customerName: null,
  customerId: null,
  contactPerson: null,
  phone: null,
  email: null,
  orderId: null,
  reference: null,
  comment: null,
  articles: null
};

// const articleBase = {
//   name: 'Artikelnamn',
//   sku: '123SKU',
//   color: 'grey',
//   claimReason: 'B',
//   orderId: 'Mitt orderID',
//   images: new Array(3).fill(null),
//   previewImages: new Array(3).fill(null),
//   completed: false,
//   qty: 3
// };

// const claimBase = {
//   customerName: 'Namn',
//   customerId: 'KundId',
//   contactPerson: 'Konaktperson',
//   phone: '049031314',
//   email: 'himself@mattiasottosson.se',
//   reference: 'Min referns',
//   comment: 'Så dåligt',
//   articles: null
// };

const modal = {
  state: {
    ...cloneDeep(claimBase)
  },

  mutations: {
    updateClaim: (state, data) => {
      const entries = Object.entries(cloneDeep(data)).filter(([k]) => k !== 'articles');
      for (const [key, val] of entries) {
        state[key] = val;
      }
    },

    addArticle: (state, article) => {
      article.id = article.id || nanoid();
      article.completed = true;

      if (state.articles === null) {
        state.articles = [];
      }

      state.articles.push(article);
    },

    updateArticle: (state, data) => {
      const article = state.articles.find(a => a.id === data.id);
      Object.assign(article, data);
    },

    removeArticle: (state, data) => {
      const article = state.articles.find(a => a.id === data.id);
      state.articles.splice(state.articles.indexOf(article), 1);
    }
  },

  actions: {
    updateClaim: ({ commit }, data) => {
      commit('updateClaim', data);
    },

    addArticle: ({ commit }, data) => {
      commit('addArticle', data);
    },

    updateArticle: ({ commit }, data) => {
      commit('updateArticle', data);
    },

    removeArticle: ({ commit }, data) => {
      commit('removeArticle', data);
    },

    addEmptyArticle: ({ commit }, data) => {
      const article = cloneDeep(articleBase);
      article.id = nanoid();
      commit('addArticle', article);
    },

    submit: async ({ commit, state }) => {
      try {
        const payload = cloneDeep(state);

        for (const article of payload.articles) {
          delete article.previewImages;
          delete article.completed;
        }

        await axios.post('claims', payload);
      } catch (e) {
        console.log(e);
        throw new Error('Could not save post');
      }
    }
  },

  getters: {
    articles: state => {
      return state.articles;
    },

    hasCompletedArticles: state => {
      return state.articles && state.articles.filter(a => a.completed).length > 0;
    },

    articleBase: () => {
      return articleBase;
    }
  }
};

export default modal;
