import axios from 'lib/axios';

const claim = {
  state: {
    claims: [],
    claim: null,
    count: {},
    from: null,
  },

  mutations: {
    setClaims: (state, data) => {
      state.from = new Date(data.from).toLocaleDateString('sv-SE', {year: 'numeric', month: 'numeric'});
      delete data.from;

      const claims = Object.values(data);
      state.claims = claims;
    },

    setClaimCount: (state, data) => {
      state.count = data;
    },
  },

  actions: {
    loadClaims: async ({ commit, dispatch, state }, from) => {
      try {
        commit('loader/setLoading', { loading: true, handler: 'claim' }, { root: true });
        const params = {}

        if(from) {
          params.from = new Date(from).toISOString().split('T')[0];
        }

        const { data } = await axios.get('claims', { params });
        commit('setClaims', data);
        dispatch('loadClaimCount');
      } catch (e) {
        console.log(e);
      } finally {
        commit('loader/setLoading', { loading: false, handler: 'claim' }, { root: true });
      }
    },

    loadClaimCount: async ({ commit, state }) => {
      const params = {}

      if(state.from) {
        params.from = new Date(state.from).toISOString().split('T')[0];
      }

      try {
        commit('loader/setLoading', { loading: true, handler: 'claim' }, { root: true });
        const { data } = await axios.get('claims/count', { params });
        commit('setClaimCount', data);
      } catch (e) {
        console.log(e);
      } finally {
        commit('loader/setLoading', { loading: false, handler: 'claim' }, { root: true });
      }
    },

    setStatus: async ({ commit, state }, { id, status }) => {
      try {
        commit('loader/setLoading', { loading: true, handler: 'claim' }, { root: true });
        await axios.put(`claims/${id}`, { status });
      } catch (e) {
        console.log(e);
      } finally {
        commit('loader/setLoading', { loading: false, handler: 'claim' }, { root: true });
      }
    },

    save: async ({ commit, dispatch, state }, { id, data }) => {
      const { status, note, customerComment, approvalMateus, approvalFactory, articles } = data;

      try {
        commit('loader/setLoading', { loading: true, handler: 'claim' }, { root: true });
        await axios.put(`claims/${id}`, { status, note, customerComment, approvalMateus, approvalFactory, articles });
        dispatch('loadClaims');
      } catch (e) {
        console.log(e);
      } finally {
        commit('loader/setLoading', { loading: false, handler: 'claim' }, { root: true });
      }
    },

    destroy: async ({ dispatch, commit, state }, { id }) => {
      try {
        commit('loader/setLoading', { loading: true, handler: 'claim' }, { root: true });
        await axios.delete(`claims/${id}`);
        dispatch('loadClaims');
      } catch (e) {
        console.log(e);
      } finally {
        commit('loader/setLoading', { loading: false, handler: 'claim' }, { root: true });
      }
    },

    close: async ({ commit, dispatch, state }, { id, data }) => {
      try {
        commit('loader/setLoading', { loading: true, handler: 'claim' }, { root: true });
        await axios.post(`claims/${id}/close`);
        dispatch('loadClaims');
      } catch (e) {
        console.log(e);
      } finally {
        commit('loader/setLoading', { loading: false, handler: 'claim' }, { root: true });
      }
    },

    setFrom: async ({ commit, dispatch, state }, date ) => {
      dispatch('loadClaims', date);
    }
  },

  getters: {
    claimById (state) {
      return (id) => {
        return state.claims.find(c => c.id === parseInt(id)) || null;
      };
    }
  }
};

export default claim;
