<template>
  <div class="container">
    <div class="copy">
      <h1>Reklamation</h1>
      <p>
        Samtliga reklamationer ska genomföras senast fem arbetsdagar efter mottagen leverans.<br>
        För att Mateus ska kunna hantera en reklamation måste nedan formuläret vara korrekt ifyllt
        med tillhörande bilder på defekten samt Mateus ordernummer, vilket du hittar på följesedeln.
        Vid kundreklamation som sker inom skälig reklamationstid krävs bifogat köpbevis från kund.
        Transportskador reklameras direkt till fraktbolaget. Kontakta respektive fraktbolag för reklamation.
      </p>
      <p>
        Efter godkänd reklamation erhålls en kredit för aktuell produkt.
        Önskas ny produkt, vänligen lägg en ny order i Mateus webshop.
      </p>
    </div>

    <ContactInfo />
    <ArticleList />
    <Comment />

    <ProgressButton text="Skicka" :callback="submit" v-if="hasCompletedArticles" />
  </div>
</template>

<script>
  import ContactInfo from 'components/contact-info';
  import ArticleList from 'components/article-list';
  import Comment from 'components/comment';
  import ProgressButton from 'components/progress-button';
  import { mapState, mapGetters } from 'vuex';

  export default {
    name: 'Claim',
    components: { ContactInfo, ArticleList, Comment, ProgressButton },
    data () {
      return {
        editableClaim: null,
        view: 'editClaim'
      };
    },

    computed: {
      ...mapState(['claim', 'newArticle']),
      ...mapGetters({ hasCompletedArticles: 'claim/hasCompletedArticles' })
    },

    methods: {
      validForm () {
        if (this.$refs.form.checkValidity()) {
          return true;
        } else {
          this.$refs.form.reportValidity();
          return false;
        }
      },

      addNewArticle () {
        if (this.validForm) {
          this.$store.dispatch('claim/updateClaim', this.editableClaim);
          this.view = 'editArticles';
        }
      },

      async submit () {
        try {
          await this.$store.dispatch('claim/submit');
          this.$router.push({ name: 'claimCreated' });
        } catch (e) {
          console.log(e);
          alert('Could not send form =(');
        }
      }

    }
  };
</script>

<style lang="stylus" scoped>
  @require '~global/colors'
  @require '~global/helpers'
  @require '~global/typo'
  @require '~global/forms'

  h1
    @extend .t-header

  h2
    @extend .t-subHeader

  p
    margin-bottom: 1rem

  .copy
    margin-bottom: 3rem

  .container
    @extend .flex-vc
    flex-direction: column
    width: 100%
    max-width: 700px
    margin-bottom: 2rem

  .component-container
    @extend .flex-vc
    width: 100%
    padding: 3rem 4rem
    margin-bottom: 4rem
    background-color: #f0f0eb

  form
    @extend .flex-vc
    width: 100%
    padding: 3rem 4rem

  @media (max-width: $tablet)
    .component-container
      padding: 2rem

    .copy
      padding: 0 2rem

  @media (max-width: $mobile)
    .component-container
      padding: 2rem 1rem
</style>
