import { render, staticRenderFns } from "./claim-created.vue?vue&type=template&id=001dd531&scoped=true&"
import script from "./claim-created.vue?vue&type=script&lang=js&"
export * from "./claim-created.vue?vue&type=script&lang=js&"
import style0 from "./claim-created.vue?vue&type=style&index=0&id=001dd531&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../tmp/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "001dd531",
  null
  
)

export default component.exports