<template>
  <form class="contact-info component-container" ref="form">
    <h2 class="t-subHeader">
      Kontaktinformation
    </h2>

    <template v-if="view === 'editClaim' || view === 'updateClaim' ">
      <div class="input-group" v-for="[model, opts] of fields" :key="model">
        <label>{{ opts.label }}</label>
        <input :type="opts.type" v-model="editableClaim[model]" :required="opts.required">
      </div>

      <input
        type="submit"
        class="button"
        value="Lägg till artikel"
        @click.prevent="addNewArticle"
        v-if="view === 'editClaim'"
      >
      <input
        type="submit"
        class="button"
        value="Spara"
        @click.prevent="view = 'editArticles'"
        v-else
      >
    </template>

    <div class="locked-container" v-if="view === 'editArticles'">
      <div class="input-group-locked" v-for="[model, opts] of filteredFields" :key="model">
        <strong>{{ opts.label }}:</strong> {{ editableClaim[model] }}
      </div>

      <button class="button--edit" @click.prevent="view = 'updateClaim'">
        Ändra
      </button>
    </div>
  </form>
</template>

<script>
  import { mapState, mapGetters } from 'vuex';
  import { cloneDeep } from 'lodash';
  import ArticleItem from 'components/article-form';
  import ModalMixin from 'mixins/modal';
  import editActions from 'components/edit-actions.vue';

  const fields = {
    customerName: { el: 'input', type: 'text', label: 'Kundnamn', required: true },
    customerId: { el: 'input', type: 'text', label: 'Kundnummer', required: true },
    contactPerson: { el: 'input', type: 'text', label: 'Kontaktperson', required: true },
    phone: { el: 'input', type: 'text', label: 'Telefonnummer', required: true },
    email: { el: 'input', type: 'email', label: 'Email', required: true },
    reference: { el: 'input', type: 'text', label: 'Er referens', required: false }
  };

  export default {
    components: { editActions },
    name: 'ContactInfo',
    mixins: [ModalMixin],

    data () {
      return {
        editableClaim: null,
        view: 'editClaim'
      };
    },

    modals: {
      ArticleItem
    },

    computed: {
      ...mapState(['claim'], ['view']),
      ...mapGetters({ hasCompletedArticles: 'claim/hasCompletedArticles' }),

      fields () {
        return Object.entries(fields);
      },
      filteredFields () {
        return this.fields.filter(([k]) => Boolean(this.claim[k]));
      }
    },

    created () {
      this.editableClaim = cloneDeep(this.claim);
    },

    methods: {
      validForm () {
        if (this.$refs.form.checkValidity()) {
          return true;
        } else {
          this.$refs.form.reportValidity();
          return false;
        }
      },

      addNewArticle () {
        if (this.validForm()) {
          this.$store.dispatch('claim/updateClaim', this.editableClaim);
          this.openModal(this.$options.modals.ArticleItem);

          // this.$store.commit('setView', 'editArticles');
          // this.$store.commit('setNewArticle', true);
          this.view = 'editArticles';
        }
      }
    }

  };
</script>

<style lang="stylus" scoped>
  @require '~global/colors'
  @require '~global/helpers'
  @require '~global/typo'
  @require '~global/forms'

  .input-group:last-of-type
    margin-bottom: 3rem

  .input-group-locked
    margin-bottom: 0.5rem
    display: block

  .locked-container
    width: 100%
    background: $white
    padding: 2rem
    margin-bottom: 1rem
    border-radius: 4px
    display: flex
    flex-direction: column
    position: relative

  .input-group-locked:last-of-type
    margin-bottom: 2rem

  .button--edit
    margin: 0 auto
</style>
