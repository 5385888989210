export default {
  methods: {
    openModal (component, data = null) {
      this.$store.dispatch('modal/openModal', { component, data });
    },

    closeModal () {
      this.$store.dispatch('modal/closeModal');
    }
  }
};
