<template>
  <div class="claims">
    <div class="sub-menu">
      <h1>
        Reklamationer
      </h1>

      <div class="searchContainer">
        <input v-model="searchValue" type="text" class="searchValue" placeholder="Sök">
        <a v-if="searchValue" class="searchClear" href="#" @click.prevent="clearSearch">
          &times;
        </a>
      </div>

      <div class="rightContainer">
        <input v-model="from" class="date" type="month">

        <select v-model="statusFilter" class="filter">
          <option v-for="(val, key) in statuses" :key="key" :value="key">
            {{ filterLabel(key) }}
          </option>
        </select>
      </div>
    </div>

    <table class="claimsTable">
      <tr>
        <th v-for="(label, header) in headers" :key="header" :class="[sortBy === header ? sortDir: '', {'is-active': sortBy === header}]" @click="sort(header)">
          {{ label }}
        </th>
        <th />
      </tr>

      <tr v-for="claim of sortedClaims" :key="claim.id">
        <td>M-{{ claim.claimId }}</td>
        <td class="customerName" >{{ claim.customerName }}</td>
        <td>{{ claim.customerId }}</td>
        <td>{{ claim.contactPerson }}</td>
        <td>{{ claim.phone }}</td>
        <td class="contactEmail">{{ claim.email }}</td>
        <td>{{ formattedDate(claim.createdAt) }}</td>
        <td>{{ formattedStatus(claim.status) }}</td>
        <td class="actions">
          <a href="#" @click.prevent="$router.push({name: 'claim', params: { id: claim.id } })"><IconMagnify /></a>
          <a href="#" @click.prevent="destroy(claim.id)"><IconTrashcan /></a>
        </td>
      </tr>
    </table>

    <ProgressButton v-if="false" text="Spara ändringar" :callback="save" />
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import { cloneDeep } from 'lodash';
  import ProgressButton from 'components/progress-button';
  import IconMagnify from 'icons/magnify.svg';
  import IconTrashcan from 'icons/trashcan.svg';

  const headers = {
    id: 'Id',
    customerName: 'Kundnamn',
    customerId: 'Kundnummer',
    contactPerson: 'Kontaktperson',
    phone: 'Telefonnumer',
    email: 'Email',
    createdAt: 'Skapad',
    status: 'Status'
  };

  const statuses = {
    all: 'Alla',
    new: 'Ohanterade',
    processing: 'Pågående',
    completed: 'Stängda',
  };

  const statusesSingular = {
    new: 'Ohanterad',
    processing: 'Pågående',
    completed: 'Stängd',
  };

  export default {
    name: 'Claims',
    components: { ProgressButton, IconMagnify, IconTrashcan },
    data () {
      return {
        headers,
        sortBy: 'createdAt',
        sortDir: 'desc',
        statusFilter: '',
        statuses,
        searchValue: null
      };
    },

    computed: {
      ...mapState('claim', ['claims','count']),

      filteredClaims () {
        let claims = this.claims;
        const statusFilter = this.statusFilter;

        if(statusFilter.length && statusFilter !== 'all') {
          claims = claims.filter(claim => claim.status === statusFilter);
        }

        if(this.searchValue && this.searchValue.length) {
          claims = _.filter(claims, _.flow(_.values, _.partialRight(_.some, _.method('match', new RegExp(this.searchValue, 'i')))));
        }

        return claims;
      },

      from:  {
        get () {
          return this.$store.state.claim.from;
        },

        set (date) {
          this.$store.dispatch('claim/setFrom', date);
        }
      },

      sortedClaims () {
        const claims = cloneDeep(this.filteredClaims);

        return claims.sort((a, b) => {
          let modifier = 1;
          if (this.sortDir === 'desc') modifier = -1;
          if (a[this.sortBy] < b[this.sortBy]) return -1 * modifier;
          if (a[this.sortBy] > b[this.sortBy]) return 1 * modifier;
          return 0;
        });
      },
    },

    watch: {
      statusFilter (val) {
        window.localStorage.setItem('statusFilter', val)
      },

      searchValue (val) {
        if(val) {
          window.localStorage.setItem('searchValue', val)
        } else {
          this.clearSearch();
        }
      }
    },

    mounted () {
      this.setLocalStorageItems();
    },

    methods: {
      formattedDate (date) {
        return new Date(date).toLocaleString('sv-SE').split(/\s/)[0];
      },

      formattedStatus (status) {
        return statusesSingular[status];
      },

      destroy (id) {
        if (confirm('Are you sure?')) {
          this.$store.dispatch('claim/destroy', { id });
        }
      },

      sort (header) {
        this.sortDir = this.sortDir === 'desc' && this.sortBy === header ? 'asc' : 'desc';
        this.sortBy = header;
        window.localStorage.setItem('sortBy', this.sortBy);
        window.localStorage.setItem('sortDir', this.sortDir);
      },

      setLocalStorageItems () {
        this.sortBy = window.localStorage.getItem('sortBy') || 'createdAt';
        this.sortDir = window.localStorage.getItem('sortDir') || 'desc';
        this.searchValue = window.localStorage.getItem('searchValue') || '';

        const filter = window.localStorage.getItem('statusFilter') || 'new';

        if(filter) {
          this.statusFilter = filter;
        }
      },

      filterLabel (key) {
        const count = this.count[key === 'all' ? 'total' : key];
        const label = this.statuses[key];
        return `${label} (${count || 0})`;
      },

      clearSearch () {
        this.searchValue = null;
        window.localStorage.removeItem('searchValue');
      }
    }

  };
</script>

<style lang="stylus" scoped>
  @require '~global/colors'
  @require '~global/typo'
  @require '~global/helpers'

  .claims
    display: flex
    flex-wrap: wrap
    padding-top: 2rem
    max-width: 1400px
    width: 100%
    height: 100%
    position: relative

  h1
    @extend .t-subHeader
    margin-bottom: 1rem
    flex-basis: 0

  select
    border: 1px solid $grey
    padding: .4rem

  .sub-menu
    width: 100%
    display: flex
    justify-content: space-between

  .rightContainer
    display: flex
    flex-direction: row
    align-self: center
    justify-content: flex-end

  .rightContainer .date,
  .rightContainer select
    padding: .4rem 1rem

  .rightContainer .date
    margin-right: 1rem
    border: 1px solid $grey

  .searchValue
    width: 16rem
    border: 1px solid $grey
    padding: .4rem .8rem
    border-radius: 1rem

  .searchContainer
    align-self: center
    position: relative

  .searchClear
    font-size: 1rem
    font-weight: bold
    color: $off-black
    position: absolute
    padding: 0 .5rem
    right: .2rem

  h1,
  .rightContainer
    flex-basis: 0
    flex-grow: 1

  table
    width: 100%
    background: $white

  td.customerName,
  td.contactEmail
    max-width: 260px
    white-space: nowrap
    overflow: hidden

  td.contactEmail
    max-width: 236px

  td,th
    padding: 0.5rem 0.8rem
    border-bottom: 1px solid $grey-light

  th:after
    content: ""
    width: 0
    height: 0
    display: inline-block
    position: relative
    margin-left: 2px
    border-left: 4px solid transparent
    border-right: 4px solid transparent
    border-top: 6px solid transparent

  th:hover
    cursor: pointer

  th.is-active.desc:after
    border-left: 4px solid transparent
    border-right: 4px solid transparent
    border-top: 6px solid black

  th.is-active.asc:after
    border-left: 4px solid transparent
    border-right: 4px solid transparent
    border-bottom: 6px solid black

  .download-xls
    display: flex
    align-items: center
    justify-content: flex-end
    font-size: .8em
    padding: 0
    right: 0
    top: 50px
    position: absolute

  .download-xls:hover
    cursor: pointer

  .icon-xls
    width: 14px
    height: 20px
    margin-right: .4rem

  th
    @extend .t-base
    @extend strong
    color: $grey-dark
    font-size: .7rem
    user-select: none

  td
    @extend .t-base
    font-size: 0.9rem

  .actions
    line-height: 0
    svg
      color: $grey-dark
      width: 20px

    svg:first-of-type
      margin-right: 4px

</style>
