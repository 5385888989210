import Claim from 'views/claim';
import ClaimCreated from 'views/claim-created';
import NotFound from 'views/not-found';

const routes = [
  {
    path: '/',
    name: 'claim',
    component: Claim
  },
  {
    path: '/created',
    name: 'claimCreated',
    component: ClaimCreated
  },
  {
    name: 'not-found',
    path: '/not-found',
    meta: { title: 'Page not found' },
    component: NotFound
  },
  {
    path: '*',
    redirect: { name: 'not-found' }
  }
];

export default routes;
