<template>
  <div class="container">
    <div class="copy">
      <h1>Tack för ditt ärende!</h1>
      <p>
        Vi beklagar att du mottagit en produkt som inte uppnår förväntad kvalité.
        Vi kommer att se över ditt ärende och återkomma till dig så snart vi kan.
      </p>

      <p>
        Om det saknas underlag i form av information eller bild så kontaktar vi er för kompletterande uppgifter.
      </p>

      <p>
        Vänligen, Mateus
      </p>
    </div>

    <button class="button" @click.prevent="restart">Skapa nytt ärende</button>
  </div>
</template>

<script>
  export default {
    methods: {
      restart() {
        window.location.href = '/';
      }
    }
  };
</script>

<style lang="stylus" scoped>
  @require '~global/colors'
  @require '~global/typo'
  @require '~global/helpers'
  @require '~global/forms'

  h1
    @extend .t-subHeader
    margin-bottom: 1rem

  h2
    @extend .t-subHeader
    margin-bottom: .6rem
    font-size: 1.3rem

  p
    margin-bottom: 1rem

  .copy
    margin-bottom: 3rem

  .container
    @extend .flex-vc
    flex-direction: column
    width: 100%
    max-width: 700px
    margin-bottom: 2rem

  @media (max-width: $tablet)
    .copy
      padding: 0 2rem

</style>
