<template>
  <div>
    <img class="image" :src="image">
    <a href="#" class="arrow arrow-left" @click.prevent="previous" />
    <a href="#" class="arrow arrow-right" @click.prevent="next" />
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    name: 'ImageModal',
    data () {
      return {
        currentIndex: 0,
      }
    },

    computed: {
      ...mapState(['modal']),
      ...mapState('order', ['settings']),

      image () {
        return this.images[this.currentIndex];
      },

      images () {
        return this.modal.data.images.filter(i => i);
      }
    },

    mounted () {
      this.currentIndex = this.modal.data.index
    },

    methods: {
      next () {
        this.currentIndex = this.currentIndex + 1 > this.images.length - 1 ? 0 : this.currentIndex + 1;
      },

      previous () {
        this.currentIndex = this.currentIndex - 1 < 0 ? this.images.length - 1 : this.currentIndex - 1;
      },
    }
  };
</script>

<style lang="stylus" scoped>
  @require '~global/colors'
  @require '~global/typo'
  @require '~global/helpers'

  img
    width: 100%
    object-fit: contain
    max-height: 80vh

  .arrow
    display: block
    padding: 20px 12px
    background: rgba(0,0,0,.1)
    border-radius: 8px
    position: absolute
    top: 50%
    transform: translateY(-50%)

  .arrow:after
    display: block
    content: ''
    width: 14px
    height: 14px
    border-top: 2px solid $white
    border-right: 2px solid $white
    position: relative

  .arrow-left
    left: 2.4rem
    &:after
      transform: rotate(-135deg);
      left: 4px;

  .arrow-right
    right: 2.4rem
    &:after
      transform: rotate(45deg);
      left: -3px;
</style>
