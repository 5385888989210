import axios from 'lib/axios';

const getSignedURL = async (file) => {
  const opts = {
    filePath: file.name,
    contentType: file.type
  };

  try {
    const { data } = await axios.put('claims/getSignedUrl', opts);
    return data;
  } catch (e) {
    console.log(e);
    alert('Could not get signed url');
    throw new Error('Could not get signed url');
  }
};

const s3Upload = async (file) => {
  let { signedURL, url } = await getSignedURL(file);

  try {
    await axios.put(signedURL, file, { headers: { 'content-type': file.type } });
  } catch (e) {
    console.log(e);
    url = null;
    alert('The image could not be uploaded');
    throw new Error('The image could not be uploaded');
  } finally {
    return url;
  }
};

export { s3Upload, getSignedURL };
