<template>
  <div class="edit">
    <a href="#" @click.prevent="$emit('editAction', 'edit', article)"><IconEdit class="icon" /></a>
    <a href="#" @click.prevent="destroy(article)"><IconTrashcan class="icon" /></a>
  </div>
</template>

<script>
  import IconTrashcan from 'icons/trashcan.svg';
  import IconEdit from 'icons/edit.svg';

  export default {
    components: { IconTrashcan, IconEdit },
    props: {
      article: {
        type: Object,
        default: null
      }
    },

    methods: {
      destroy(article) {
        if(confirm('Är du säker?')) {
          this.$emit('editAction', 'destroy', article);
        }
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @require '~global/colors'
  @require '~global/helpers'
  @require '~global/typo'

  .edit
    border: 1px solid $off-black-lighter
    border-radius: 10px
    padding: .5rem  .6rem
    line-height: 0
    position: absolute
    right: 2rem

  .edit:hover
    background: $off-black
    & a
      color: $grey

  .icon
    width: 22px
    height: 22px

  .edit a:hover
    color: $white

  .edit a:first-of-type
    margin-right: .3rem

  @media (max-width: $mobile)
    .edit
      right: 1rem
</style>
