<template>
  <button :class="['progress-button', {'is-active': isActive}]" @click.prevent="click" :disabled="isActive">
    <div class="content">
      <span class="text">{{ text }}</span>

      <!-- eslint-disable vue/max-attributes-per-line -->
      <div class="spinner-container">
        <svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30" />
        </svg>
      </div>
    </div>
  </button>
</template>

<script>
  export default {
    name: 'ProgressButton',
    props: {
      text: {
        type: String,
        default: ''
      },
      callback: {
        type: Function,
        default: () => {}
      }
    },
    data () {
      return {
        isActive: false
      };
    },

    methods: {
      async click () {
        this.isActive = true;
        await this.callback();
        this.isActive = false;
      }
    }
  };
</script>

<style lang="stylus" scoped>
  @require '~global/colors'
  @require '~global/typo'
  @require '~global/forms'

  $offset = 187
  $duration = 1.4s

  .progress-button
    @extend .button--dark

  .progress-button.is-active,
  .progress-button.is-active:hover
    background-color: #ffbe64
    color: rgb(30,30,30)
    color: $white

  .content
    position: relative

  .spinner-container
    position: absolute
    line-height: 0
    top: 50%
    left: 50%
    transform: translate(-50% ,-50%)
    display: none

  .spinner
    width: 20px
    height: 20px
    animation: rotator $duration linear infinite

  .path
    stroke-dasharray: $offset
    stroke-dashoffset: 0
    transform-origin: center
    stroke: $white
    animation: dash $duration ease-in-out infinite, colors ($duration) ease-in-out infinite

  .action .path
    stroke: $white
    animation: dash $duration ease-in-out infinite

  .is-active
    .text
      visibility: hidden

    .spinner-container
      display: block

 @keyframes rotator
    0%
      transform: rotate(0deg)
    100%
      transform: rotate(270deg)

  @keyframes colors
    0%
      stroke: $white
    100%
      stroke: $white

  @keyframes dash
    0%
      stroke-dashoffset: $offset
    50%
      stroke-dashoffset: ($offset / 4)
      transform: rotate(135deg)
    90%
      stroke-dashoffset: $offset
      transform: rotate(450deg)
    100%
      stroke-dashoffset: $offset
      transform: rotate(450deg)
</style>
