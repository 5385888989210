import Vue from 'vue';
import Vuex from 'vuex';
import modal from './modules/modal';
import claim from './modules/claim';
import loader from './modules/loader';

const modules = { modal, claim, loader };

const state = {
  notFound: false
};

const mutations = {};

for (const [name, module] of Object.entries(modules)) {
  modules[name] = { namespaced: true, ...module };
}

Vue.use(Vuex);
const store = new Vuex.Store({ state, mutations, modules });

export default store;
