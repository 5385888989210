const environments = {
  development: {
    domain: 'localhost',
    apiURL: 'http://localhost:3000/development',
    apiURLAdmin: 'http://localhost:3000/development'
  },

  staging: {
    domain: 'mattiasottosson.se',
    apiURL: 'https://n60rdvky7a.execute-api.eu-north-1.amazonaws.com/production',
    apiURLAdmin: 'https://n60rdvky7a.execute-api.eu-north-1.amazonaws.com/production'
  },

  production: {
    domain: 'mateuscollection.com',
    apiURL: 'https://n60rdvky7a.execute-api.eu-north-1.amazonaws.com/production',
    apiURLAdmin: 'https://n60rdvky7a.execute-api.eu-north-1.amazonaws.com/production'
  }
};

const domain = window.location.hostname.split('.').slice(-2).join('.');

const env = environments[Object.keys(environments).find(e => environments[e].domain === domain)];
if (!env) {
  throw new Error('Couldn\'t decide current environment on domain ' + domain);
}

const appConfig = {
  ...env
};

export default appConfig;
