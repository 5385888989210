const modal = {
  state: {
    visible: false,
    component: null,
    data: null
  },

  mutations: {
    setComponent: (state, { component, data }) => {
      state.component = component;

      if (data) {
        state.data = data;
      }
    },

    setVisibility: (state, isVisible) => (
      state.visible = isVisible
    )
  },

  actions: {
    openModal: ({ commit }, { component, data }) => {
      commit('setComponent', { component, data });
      commit('setVisibility', true);
    },

    closeModal: ({ commit }) => {
      commit('setVisibility', false);

      setTimeout(() => {
        commit('setComponent', { component: null, data: null });
      }, 200);
    }
  }
};

export default modal;
