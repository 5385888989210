<template>
  <form v-if="editableArticle" ref="form" class="article component-container">
    <h2 class="t-subHeader">
      {{ editableArticle.id ? 'Ändra artikel' : 'Lägg till artikel' }}
    </h2>

    <div class="input-group">
      <label>Mateus ordernummer</label>
      <input v-model="editableArticle.orderId" type="text" required>
    </div>

    <div class="input-group">
      <label>Artikelnamn</label>
      <input v-model="editableArticle.name" type="text" required>
    </div>

    <div class="input-group">
      <label>Artikelnummer</label>
      <input v-model="editableArticle.sku" type="text" required>
    </div>

    <div class="input-group">
      <label>Orsakskod</label>
      <select v-model="editableArticle.claimReason" required>
        <option value="" disabled selected>
          Välj orsakskod
        </option>

        <option v-for="(code, key) of claimReasons" :key="key" :value="key">
          {{ key }} - {{ code.label }}
        </option>
      </select>
    </div>

    <div class="input-group">
      <label>Färg</label>
      <select v-model="editableArticle.color" required>
        <option value="" disabled selected>
          Välj färg
        </option>

        <option v-for="[slug, color] of sortedColors" :key="slug" :value="slug">
          {{ color.label }}
        </option>
      </select>
    </div>

    <div class="input-group">
      <label>Antal</label>
      <input v-model="editableArticle.qty" type="number" required>
    </div>

    <div class="input-group">
      <label>Bilder</label>

      <p class="disclaimer">
        Alla ärenden ska innehålla tydlig bild på fram- och baksida på den defekta produkten
      </p>

      <ul class="images">
        <li
          v-for="(img, imgIndex) of editableArticle.previewImages"
          :key="imgIndex"
          class="image"
          :style="{'backgroundImage': `url(${img})`}"
          @click="selectImage"
        >
          <IconPlus class="icon" />

          <input
            :data-img-index="imgIndex"
            class="file-select"
            type="file"
            accept="image/*"
            @change="previewImage"
          >
        </li>
      </ul>
    </div>

    <input
      type="submit"
      class="button"
      :value="`${editableArticle.id ? 'Spara ändringar' : 'Lägg till'}`"
      :disabled="loading"
      @click.prevent="saveArticle"
    >
  </form>
</template>

<script>
  import IconPlus from 'icons/plus.svg';
  import { cloneDeep } from 'lodash';
  import { s3Upload } from 'lib/s3';
  import { mapState } from 'vuex';
  import { colors, claimReasons } from 'lib/article';
  import ModalMixin from 'mixins/modal';

  export default {
    name: 'ClaimArticle',
    components: { IconPlus },
    mixins: [ModalMixin],
    data () {
      return {
        editableArticle: {},
        loading: false,
        claimReasons
      };
    },

    computed: {
      ...mapState(['view', 'newArticle', 'modal']),
      sortedColors () {
        return Object.entries(colors).sort(([, a], [, b]) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }

          return 0;
        });
      },

      numPhotos () {
        return (this.editableArticle && this.editableArticle.previewImages || []).filter(a => a).length
      },

      numNullPhotos () {
        return (this.editableArticle && this.editableArticle.previewImages || []).filter(a => !a).length
      }
    },

    watch: {
      numPhotos () {
        if (this.numPhotos > 0 && this.numNullPhotos < 3 && this.numPhotos % 3 == 0) {
          this.addAdditionalImages();
        }
      }
    },

    mounted () {
      const article = this.modal.data || this.$store.getters['claim/articleBase'];
      this.editableArticle = cloneDeep(article);
    },

    methods: {
      validForm () {
        if (this.$refs.form.checkValidity()) {
          return true;
        } else {
          this.$refs.form.reportValidity();
          return false;
        }
      },

      async saveArticle () {
        if (this.validForm()) {
          const action = this.modal.data ? 'claim/updateArticle' : 'claim/addArticle';
          await this.$store.dispatch(action, this.editableArticle);
          this.editableArticle = null;
          this.closeModal();
        }
      },

      selectImage (e) {
        const fileInput = e.target.querySelector('.file-select');
        if (fileInput) {
          fileInput.click();
        }
      },

      async uploadImage (file) {
        return s3Upload(file);
      },

      async previewImage (e) {
        const { imgIndex } = e.target.dataset;
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
          this.$set(this.editableArticle.previewImages, imgIndex, reader.result);
        };

        this.loading = true;
        e.target.closest('li').classList.add('loading');
        const url = await this.uploadImage(file);
        this.$set(this.editableArticle.images, imgIndex, url);
        e.target.closest('li').classList.remove('loading');
        this.loading = false;
      },

      addAdditionalImages () {
        const emptyImages = new Array(3).fill(null);
        this.editableArticle.images.push(...emptyImages);
        this.editableArticle.previewImages.push(...emptyImages)
      }
    }
  };
</script>

<style lang="stylus" scoped>
  @require '~global/colors'
  @require '~global/helpers'
  @require '~global/typo'
  @require '~global/forms'

  .component-container
    @extend .flex-vc
    width: 100%
    padding: 3rem 4rem
    background-color: #f0f0eb

  .disclaimer
    padding: 1rem 0
    font-size: 0.8rem
    font-style: italic

  .images
    width: 100%
    display: grid
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 4px

  .image
    @extend .flex-vh
    background: $white
    background-size: cover
    border: 1px solid $grey-light
    padding: calc(33% + 10px) 0
    border-radius: 10px
    position: relative

  .image.loading:after
    background: rgba(0,0,0,.4) url('~icons/loading-circle.svg?bg') center no-repeat
    content: ""
    width: 100%
    height: 100%
    border-radius: 10px
    position: absolute

  .image:hover
    cursor: pointer

  .image .icon
    pointer-events: none

  .file-select
    display: none
    pointer-events: none

  .input-group:last-of-type
    margin-bottom: 3rem

  input[type="submit"]:disabled
    opacity: .3

  @media (max-width: $tablet)
    .component-container
      border-radius: 8px
      padding: 2rem

  @media (max-width: $mobile)
    .component-container
      padding: 2rem 1.3rem
</style>
