<template>
  <div v-if="articles" v-show="!modal.component" class="article component-container">
    <template v-if="articles.length">
      <h2 class="t-subHeader">
        Tillagda artiklar
      </h2>

      <ul ref="articleList" class="article-list">
        <li v-for="article of articles" :key="article.id" class="article">
          <edit-actions :article="article" @editAction="editAction" />

          <ul class="article-info">
            <li><strong>Ordernummer:</strong> {{ article.orderId }}</li>
            <li><strong>Artikelnamn:</strong> {{ article.name }}</li>
            <li><strong>Artikelnummer:</strong> {{ article.sku }}</li>
            <li><strong>Färg:</strong> {{ colors[article.color].label }}</li>
            <li><strong>Orsakskod:</strong> {{ article.claimReason }} - {{ claimReasons[article.claimReason].label }}</li>
            <li><strong>Antal:</strong> {{ article.qty }}</li>
          </ul>

          <ul class="images">
            <li v-for="(img, index) of article.images.filter(url => url)" :key="index" class="image" :style="{'backgroundImage': `url(${img})`}" />
          </ul>
        </li>
      </ul>
    </template>

    <input
      v-if="(hasCompletedArticles && !newArticle) || articles.length === 0"
      type="submit"
      class="button"
      value="Lägg till ny artikel"
      @click.prevent="addNewArticle"
    >
  </div>
</template>

<script>
  import { mapGetters, mapState } from 'vuex';
  import ModalMixin from 'mixins/modal';
  import ArticleForm from 'components/article-form';
  import { colors, claimReasons } from 'lib/article';
  import editActions from 'components/edit-actions.vue';

  export default {
    name: 'Articles',
    components: { editActions },
    mixins: [ModalMixin],

    data () {
      return {
        colors,
        claimReasons
      };
    },
    modals: {
      ArticleForm
    },
    computed: {
      ...mapState(['view', 'newArticle']),
      ...mapState(['modal']),
      ...mapGetters({ articles: 'claim/articles', hasCompletedArticles: 'claim/hasCompletedArticles' })
    },

    updated () {
      if(this.$refs.articleList) {
        this.$refs.articleList.scrollIntoView({behavior: "smooth", block: "end"})
      }
    },

    methods: {
      addNewArticle () {
        this.openModal(this.$options.modals.ArticleForm, null);
      },

      editAction (action, article) {
        if(action === 'edit') {
          this.openModal(this.$options.modals.ArticleForm, article);
        } else if (action === 'destroy') {
          this.$store.dispatch('claim/removeArticle', article);
        }
      }
    }
  };
</script>

<style lang="stylus" scoped>
  @require '~global/colors'
  @require '~global/helpers'
  @require '~global/typo'
  @require '~global/forms'

  .article-list
    width: 100%
    margin-bottom: 1rem

  .article
    background: $white
    display: flex
    flex-direction: column
    padding: 2rem
    margin-bottom: 2rem
    border-radius: 4px
    position: relative

  .button--edit
    margin: 0 auto

  .container
    margin-bottom: 4rem

  .article-info
    margin-bottom: 0rem

  .article-info li
    margin-bottom: .5rem

  .article-info li:last-of-type
    margin-bottom: 0

  .images
    width: 100%
    display: grid
    grid-template-columns: repeat(3, 1fr);
    column-gap: 4px

  .image
    @extend .flex-vh
    background: $white
    border: 1px solid $grey-light
    padding: 50% 0
    border-radius: 10px

  .image
    background-size: cover

  .image .icon
    pointer-events: none

  .file-select
    display: none
    pointer-events: none

  @media (max-width: $mobile)
    .article
      padding: 2rem 1.2rem
</style>
