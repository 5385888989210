import Claims from 'views/admin/claims';
import Claim from 'views/admin/claim';
import Export from 'views/admin/export';
import NotFound from 'views/not-found';
import { authGuard } from '../auth/auth-guard';

const routes = [
  {
    path: '/admin',
    name: 'claims',
    component: Claims,
    meta: { title: 'Claims' },
    beforeEnter: authGuard
  },
  {
    path: '/admin/claims/:id',
    name: 'claim',
    component: Claim,
    meta: { title: 'Claim' },
    beforeEnter: authGuard
  },
  {
    path: '/admin/export',
    name: 'export',
    component: Export,
    meta: { title: 'Export' },
    beforeEnter: authGuard
  },
  {
    name: 'not-found',
    path: '/not-found',
    meta: { title: 'Page not found' },
    component: NotFound
  },
  {
    path: '*',
    redirect: { name: 'not-found' }
  }
];

export default routes;
