export default {
  state: {
    loading: false,
    queue: []
  },
  mutations: {
    setLoading: (state, params) => {
      const { loading, handler } = params;

      if (handler === undefined) {
        throw new Error('Missing loading handler');
      }

      if (loading) {
        state.queue.push(handler);
      } else {
        const index = state.queue.indexOf(handler);
        if (index !== -1) {
          state.queue.splice(index, 1);
        }
      }

      state.loading = state.queue.length > 0;
    }
  }
};
