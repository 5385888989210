<template>
  <div class="container">
    <h1>
      Exportera
    </h1>

    <div class="inner">
      <h2>Välj datum</h2>
      <form ref="form">
        <label for="from">Från</label>
        <input id="from" v-model="from" type="date" required>

        <label for="to">Till</label>
        <input id="to" v-model="to" type="date" required>

        <ProgressButton class="button" text="Exportera" :callback="exportXls" />

        <download-excel
          v-if="claims"
          ref="download"
          :name="exportName"
          :data="exportData"
          :fields="exportHeaders"
          class="download-xls"
        />
      </form>
    </div>
  </div>
</template>

<script>
  import ProgressButton from 'components/progress-button';
  import { cloneDeep, pick } from 'lodash';
  import axios from 'lib/axios';

  const headers = {
    id: 'Id',
    customerName: 'Kundnamn',
    customerId: 'Kundnummer',
    contactPerson: 'Kontaktperson',
    phone: 'Telefonnumer',
    email: 'Email',
    createdAt: 'Skapad',
    status: 'Status'
  };

  const articleHeaders = {
    sku: 'Artikelnummer',
    name: 'Artikelnamn',
    color: 'Färg',
    qty: 'Antal',
    claimReason: 'Returorssak',
    orderId: 'Ordernummer',
  };

  const extraHeaders = {
    approvalFactory: 'Granskad fabrik',
    approvalMateus: 'Godkänd Mateus',
  };

  export default {
    name: 'Export',
    components: {
      ProgressButton
    },
    data: () => ({
      from: '',
      to: '',
      claims: null,
      headers
    }),

    computed: {
      exportHeaders () {
        const claimHeaders = cloneDeep(this.headers);
        delete claimHeaders.id;
        const headers = {  ...{claimId: 'Id' }, ...claimHeaders, ...extraHeaders, ...articleHeaders };
        return Object.entries(headers).reduce((acc, [key, value]) => (acc[value] = key, acc), {});
      },

      exportData () {
        const claims = [];
        const claimHeaders = cloneDeep(this.headers);
        delete claimHeaders.id;

        const headers = {  ...{claimId: 'Id' }, ...claimHeaders, ...extraHeaders }
        const headerKeys = Object.keys(headers);

        for (const claim of this.claims) {
          for (const article of claim.articles) {
            const c = { ...pick(claim, headerKeys), ...pick(article, Object.keys(articleHeaders)) }
            c.approvalFactory = claim.approvalFactory;
            c.approvalMateus = article.approvalMateus
            claims.push(c);
          }
        }

        return claims;
      },

      exportName () {
        return `claims-${new Date().toISOString().split('T')[0]}-${+new Date()}`;
      }
    },

    methods: {
      async exportXls (e) {
        if(!this.$refs.form.reportValidity()) {
          return;
        }

        if(this.claims?.length) {
          this.claims.length = 0;
        }

        const params = {from: this.from, to: this.to};
        const { data } = await axios.get('claims', { params });
        delete data.from;

        this.claims = Object.values(data);

        if(this.claims.length) {
          this.$nextTick(()  => {
            this.$refs.download.$el.click();
          });
        } else {
          alert('Inga reklamationer för det valda datumet');
        }
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @require '~global/colors'
  @require '~global/typo'
  @require '~global/helpers'
  @require '~global/forms'

  .container
    display: flex
    flex-wrap: wrap
    padding-top: 2rem
    max-width: 1400px
    width: 100%
    height: 100%
    position: relative

  h1
    @extend .t-subHeader
    margin-bottom: 1rem

  h2
    @extend .t-tertiaryHeader

  p
    margin-bottom: 1rem

  .inner
    width: 100%
    background: $white
    padding: 1.4rem 1.6rem
    margin-bottom: 2rem
    flex-wrap: wrap

  input
    margin-bottom: 1rem

  .button
    display: block

  .download-xls
    display: none
    pointer-events: none
</style>
