import axios from 'axios';
import AppConfig from '@/lib/app-config';

const namespace = window.location.pathname.split('/')[1] === 'admin' ? 'admin' : 'app';

const axiosInstance = axios.create({
  baseURL: namespace === 'admin' ? AppConfig.apiURLAdmin : AppConfig.apiURL,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
});

export default axiosInstance;
