<template>
  <div v-if="claim" class="claim-container">
    <h1>
      Kontaktinformation
    </h1>

    <div v-if="claim" class="claim">
      <table>
        <tr>
          <td><strong>ID</strong></td><td>M-{{ claim.claimId }}</td>
        </tr>

        <tr>
          <td><strong>Skapad</strong></td><td>{{ formattedDate(claim.createdAt) }}</td>
        </tr>

        <tr>
          <td><strong>Status</strong></td>
          <td>
            <select ref="status" v-model="editable.status">
              <option value="new">
                Ohanterad
              </option>
              <option value="processing">
                Pågående
              </option>
              <option value="completed">
                Stängd
              </option>
            </select>
          </td>
        </tr>

        <tr>
          <td><strong>Granskad fabrik</strong></td>
          <td>
            <select ref="status" v-model="editable.approvalFactory">
              <option value="waiting">
                Ohanterad
              </option>
              <option value="true">
                Ja
              </option>
            </select>
          </td>
        </tr>

        <tr v-for="(field, key) of fields" :key="key">
          <td><strong>{{ field.label }} </strong></td><td>{{ claim[key] }}</td>
        </tr>
      </table>
    </div>

    <div class="note">
      <h1>
        Interna Anteckningar Mateus
      </h1>

      <p>(Ej synligt för kund)</p>

      <form>
        <textarea v-model="editable.note" />
      </form>
    </div>

    <h1>
      Tillagda artiklar
    </h1>

    <div v-if="claim">
      <ul class="article-list">
        <li v-for="article of editable.articles" :key="article.id" class="article claim">
          <table class="article-table">
            <tr>
              <td><strong>Godkänd Mateus</strong></td>
              <td>
                <select v-model="article.approvalMateus">
                  <option value="waiting">
                    Ohanterad
                  </option>
                  <option value="true">
                    Ja
                  </option>
                  <option value="false">
                    Nej
                  </option>
                </select>
              </td>
            </tr>

            <tr v-if="false">
              <td><strong>Granskad Fabrik</strong></td>
              <td>
                <select v-model="article.approvalFactory">
                  <option value="waiting">
                    Ohanterad
                  </option>
                  <option value="true">
                    Ja
                  </option>
                  <option value="false">
                    Nej
                  </option>
                </select>
              </td>
            </tr>

            <tr v-if="false">
              <td><strong>Factory status</strong></td>
              <td>
                <select v-model="article.factoryCode">
                  <option v-for="(field, key) in factoryCodes" :key="key" :value="key">
                    {{ field.label }}
                  </option>
                </select>
              </td>
            </tr>

            <tr v-for="(field, key) of articleFields" :key="key">
              <td><strong>{{ field.label }} </strong></td>
              <td>
                <template v-if="key === 'color'">
                  {{ colors[article[key]].label }}
                </template>
                <template v-else-if="key === 'claimReason'">
                  <select v-model="article[key]">
                    <option v-for="(fcField, fcKey) in claimReasons" :key="fcKey" :value="fcKey">
                      {{ fcKey }} - {{ fcField.label }}
                    </option>
                  </select>
                </template>
                <template v-else-if="key === 'sku'">
                  <input v-model="article[key]" type="text">
                </template>
                <template v-else>
                  {{ article[key] }}
                </template>
              </td>
            </tr>

            <tr>
              <td><strong>Kommentar till kund</strong></td>
              <td>
                <form>
                  <textarea v-model="article.customerComment" class="article-comment" />
                </form>
              </td>
            </tr>

            <tr>
              <td><strong>Bilder</strong></td>
              <td>
                <ul class="images">
                  <li
                    v-for="(img, index) of article.images.filter(url => url)"
                    :key="index"
                    class="image"
                    :style="{'backgroundImage': `url(${img})`}"
                    @click="openImageModal(article.images, index)"
                  />
                </ul>
              </td>
            </tr>
          </table>
        </li>
      </ul>
    </div>

    <div v-if="claim" class="customerComment">
      <h1>
        Kommentar till kund
      </h1>

      <p>(Kommer med i mailet när ärendet stängs)</p>

      <form>
        <textarea v-model="editable.customerComment" />
      </form>
    </div>

    <button v-if="dirty" class="button changes" @click="save">
      Spara ändringar
    </button>

    <button v-if="!completed && adminUser" class="button close" @click="close">
      Stäng och skicka bekräftelse
    </button>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import ModalMixin from 'mixins/modal';
  import ArticleImage from 'components/admin/modals/article-image';
  import { colors, claimReasons } from 'lib/article';
  import { cloneDeep, isEqual } from 'lodash';

  const fields = {
    customerName: { label: 'Kundnamn' },
    customerId: { label: 'Kundnummer' },
    contactPerson: { label: 'Kontaktperson' },
    phone: { label: 'Phone' },
    email: { label: 'Email' },
    reference: { label: 'Er referens' },
    comment: { label: 'Kommentar' }
  };

  const articleFields = {
    orderId: { label: 'Mateus ordernummer' },
    name: { label: 'Artikelnamn' },
    sku: { label: 'Artikelnummer' },
    color: { label: 'Färg ' },
    claimReason: { label: 'Orsakskod' },
    qty: { label: 'Antal ' }
  };

  const factoryCodes = {
    waiting: { label: 'Ohanterad' },
    AA: { label: 'AA' },
    A: { label: 'A' },
    B: { label: 'B' },
    C: { label: 'C ' },
    D: { label: 'D' },
  }

  export default {
    name: 'Claims',
    mixins: [ModalMixin],
    modals: { ArticleImage },
    beforeRouteLeave (to, from, next) {
      if(this.dirty) {
        if(confirm('Du har osparade ändringar. Vill du forsätta?') && !this.closed) {
          next()
        }
      } else {
        next()
      }
    },

    data () {
      return {
        fields,
        articleFields,
        colors,
        claimReasons,
        factoryCodes,
        customerComment: null,
        dirty: false,
        closed: false,
        editable: {
          status: null,
          note: null,
          approvalMateus: null,
          approvalFactory: null,
          articles: null,
          customerComment: null
        }
      };
    },

    computed: {
      ...mapGetters({ claimById: 'claim/claimById' }),

      claim () {
        return this.claimById(this.$route.params.id);
      },

      watchedEditable () {
        return JSON.parse(JSON.stringify(this.editable));
      },

      completed () {
        return this.editable.status == 'completed'
      },

      adminUser () {
        return !this.$auth.user.email.includes('cmfonseca.com');
      },

      allArticlesHandled () {
        return this.editable?.articles?.every(article => article.approvalMateus !== 'waiting');
      }
    },

    watch: {
      claim: {
        deep: true,
        handler (claim, old) {
          this.setEditable();
        }
      },

      watchedEditable: {
        deep: true,
        handler (val, oldVal) {
          if([...new Set(Object.values(oldVal))][0] === null) {
            return;
          }

          if(!isEqual(val, oldVal)) {
            this.dirty = true;
          }
        }
      }
    },

    mounted () {
      if(this.claim) {
        this.setEditable();
      }
    },

    methods: {
      formattedDate (date) {
        return new Date(date).toLocaleString('sv-SE').split(/\s/)[0];
      },

      openImageModal (images, index) {
        this.openModal(this.$options.modals.ArticleImage, { images, index, closeButton: true });
      },

      setEditable () {
        const { status, note, customerComment, articles, approvalMateus, approvalFactory } = cloneDeep(this.claim);
        this.editable = { status, note, customerComment, articles, approvalMateus, approvalFactory };
      },

      async save (e) {
        await this.$store.dispatch('claim/save', { id: this.claim.id, data: this.editable  });
        this.dirty = false;
      },

      async close (e) {
        if(!this.allArticlesHandled) {
          alert('Du måste hantera alla artiklar innan du stänger ärendet.');
          return;
        }

        if(confirm('Vill du stänga ärendet?')) {
          await this.$store.dispatch('claim/save', { id: this.claim.id, data: this.editable  });
          this.dirty = false;

          await this.$store.dispatch('claim/close', { id: this.claim.id });
          this.closed = true;
          this.$router.push('/admin');
        }
      }
    }
  };
</script>

<style lang="stylus" scoped>
  @require '~global/colors'
  @require '~global/typo'
  @require '~global/helpers'
  @require '~global/forms'

  .claim-container
    @extend .flex-c
    padding: 2rem 0 4em 0
    max-width: 1400px
    width: 100%

  select
    border: 1px solid $off-black
    padding: .4rem

  h1
    @extend .t-subHeader
    margin-bottom: 1rem

  h2
    @extend .t-subHeader
    margin-bottom: .6rem
    font-size: 1.3rem

  .claim
    width: 100%
    background: $white
    padding: 1.4rem 1.6rem
    margin-bottom: 2rem

  td
    @extend .t-base
    padding-right: 1.4rem

  td:first-child
    padding-top: .3rem
    padding-bottom: .3rem

  .article
    margin-bottom: 3rem

  .article-table
    margin-bottom: .4rem

  .article-table tr td:first-child
    display: flex

  .article-table tr td:last-child
    width: 400px

  .claim-status
    margin-bottom: 3rem

  .images
    height: 100px
    display: flex

  .image
    width: 100px
    height 100px
    background-size: cover
    margin-right: 1rem

  textarea
    width: 100%
    min-height: 10rem
    padding: .4rem 1rem

  input[type="text"],
  .article-comment
    @extend .t-base
    width: 100%
    border: 1px solid $off-black
    padding: .4rem

  .article-comment
    min-height: 6.1rem

  .note,
  .customerComment
    margin-bottom: 2rem
    h1
      margin-bottom: 0rem

    p
      margin-bottom: 1rem

  .button
    margin: 0 auto

  .button.changes
    margin-bottom: 1.2rem

  .button.close
    @extend .button--dark

</style>
