const claimReasons = {
  A: { label: 'Skadad glasyr / spricka under glasyr / krackelerad under glasyr ' },
  B: { label: 'Färgdefekt / Fingeravtryck' },
  C: { label: 'Ostadig' },
  D: { label: 'Transportskada' }
};

const colors = {
  blue: { label: 'Blå' },
  cinnamon: { label: 'Cinnamon' },
  green: { label: 'Grön' },
  grey: { label: 'Grå' },
  icyBlue: { label: 'Icy Blue' },
  lightBlue: { label: 'Ljusblå' },
  lightPink: { label: 'Ljusrosa' },
  ocean: { label: 'Ocean' },
  orange: { label: 'Orange' },
  pink: { label: 'Rosa' },
  plum: { label: 'Plommon' },
  purple: { label: 'Viol' },
  sand: { label: 'Sand' },
  turquoise: { label: 'Turkos' },
  white: { label: 'Vit' },
  yellow: { label: 'Gul' },
  red: { label: 'Röd' }
};

export { colors, claimReasons };
