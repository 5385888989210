import Vue from 'vue';
import Vuex from 'vuex';
import modal from './modules/modal';
import claim from './modules/claim';

const modules = { modal, claim };

const state = {
  notFound: false,
  view: 'editClaim',
  newArticle: false
};

const mutations = {
  setView: (state, data) => {
    state.view = data;
  },

  setNewArticle: (state, data) => {
    state.newArticle = data;
  }
};

for (const [name, module] of Object.entries(modules)) {
  modules[name] = { namespaced: true, ...module };
}

Vue.use(Vuex);
const store = new Vuex.Store({ state, mutations, modules });

export default store;
