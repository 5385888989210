<template>

  <form class="contact-info component-container" ref="form" v-if="hasCompletedArticles">
    <h2 class="t-subHeader">
      Kommentar
    </h2>

    <div class="input-group" v-for="[model, opts] of fields" :key="model">
      <label>{{ opts.label }}</label>
      <input :type="opts.type" v-model="editableClaim[model]" :required="opts.required" @blur="updateClaim">
    </div>
  </form>
</template>

<script>
  import { mapState, mapGetters } from 'vuex';
  import { cloneDeep } from 'lodash';

  const fields = {
    comment: { el: 'input', type: 'text', label: 'Kommentar', required: false }
  };

  export default {
    name: 'Comment',

    data () {
      return {
        editableClaim: null,
      };
    },

    computed: {
      ...mapState(['claim'], ['view']),
      ...mapGetters({ hasCompletedArticles: 'claim/hasCompletedArticles' }),

      fields () {
        return Object.entries(fields);
      },
      filteredFields () {
        return this.fields.filter(([k]) => Boolean(this.claim[k]));
      }
    },

    created () {
      this.editableClaim = cloneDeep(this.claim);
    },

    methods: {
      updateClaim () {
        this.$store.dispatch('claim/updateClaim', {comment: this.editableClaim.comment});
      }
    }
  };
</script>

<style lang="stylus" scoped>
  @require '~global/colors'
  @require '~global/helpers'
  @require '~global/typo'
  @require '~global/forms'

  .input-group:last-of-type
    margin-bottom: 3rem

  .input-group-locked
    margin-bottom: 0.5rem
    display: block

  .locked-container
    width: 100%
    background: $white
    padding: 2rem
    margin-bottom: 1rem
    border-radius: 4px
    display: flex
    flex-direction: column
    position: relative

  .input-group-locked:last-of-type
    margin-bottom: 2rem

  .button--edit
    margin: 0 auto
</style>
