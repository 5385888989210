<template>
  <div v-show="isVisible" ref="modal" :class="['modal', modalName]">
    <div ref="component" :class="['component', animationClass, {'is-active': isVisible}]">
      <button v-if="hasCloseButton" class="close" @click="closeModal" />
      <component :is="modal.component" />
    </div>
  </div>
</template>

<script>
  import ModalMixin from 'mixins/modal';
  import { mapState } from 'vuex';

  export default {
    name: 'ModalContainer',
    mixins: [ModalMixin],
    data () {
      return { };
    },

    computed: {
      ...mapState(['modal']),

      isVisible () {
        return this.modal.visible;
      },

      modalName () {
        return this.modal.component && this.modal.component.name;
      },

      animationClass () {
        return this.modalName === 'ClaimArticle' ? 'fade-in' : 'fade-in-up';
      },

      hasCloseButton () {
        return this.modal.data && this.modal.data.closeButton;
      }
    },

    watch: {
      isVisible (isVisible) {
        this.$nextTick(() => {
          if (isVisible) {
            const height = this.$refs.component.offsetHeight;
            document.body.style.setProperty('--modal-height', height + 'px');

            if (this.$refs.component.getBoundingClientRect().top < 0) {
              const y = this.$refs.component.offsetTop - 20;
              window.scrollTo({ top: y, behavior: 'smooth' });
            }
          }
        });
      }
    },

    mounted () {
      this.bindEventListeners();
    },

    destroyed () {
      this.removeEventListeners();
    },

    methods: {
      bindEventListeners () {
        document.body.addEventListener('keyup', this.closeModal);
      },

      removeEventListeners () {
        document.body.removeEventListener('keyup', this.closeModal);
      },

      closeModal (e) {
        if(e.type === 'click') {
          this.$store.dispatch('modal/closeModal');
          return;
        }

        if ((e && e.code && e.code === 'Escape')) {
          this.$store.dispatch('modal/closeModal');
        }
      }
    }
  };

</script>

<style lang="stylus" scoped>
  @require '~global/colors'
  @require '~global/helpers'
  @require '~global/breakpoints'

  .modal
    @extend .flex-vh
    background: rgba(0,0,0,.4)
    height: 100%
    width: 100%
    position: fixed
    z-index: 99

  .modal.ClaimArticle
    position: absolute
    top: 0
    right: 0
    bottom: 0
    left: 0

  .close
    @extend .flex-vh
    background: $white url('~icons/close.svg?bg') center no-repeat
    background-size: 14px
    line-height: 0
    border-radius: 50%
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2)
    width: 60px;
    height: 60px;
    position: absolute
    top: 0
    right: 10px
    border: 0
    transform: translate(0%, -50%)
    z-index: 2

  .close:hover
    cursor: pointer

  .component
    animation-duration: .4s
    width: 100%
    max-width: 680px
    background: $white
    border-radius: 14px
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2)
    padding: 2rem
    position: relative

  .admin .component
    max-width: 60vw

  @media (max-width: $tablet)
    .component
      padding: 1rem

  @media (max-width: $mobile)
    .component
      padding: .6rem

</style>
